:root {
  --gallery-columns: 4;
}

@media (max-width: 1200px) {
  :root {
    --gallery-columns: 3;
  }
}

@media (max-width: 800px) {
  :root {
    --gallery-columns: 2;
  }
}

@media (max-width: 400px) {
  :root {
    --gallery-columns: 1;
  }
}

:root {
  --gallery-item-width: calc(100vw / (var(--gallery-columns)));
  --gallery-item-height: var(--gallery-item-width);
}

.react-gallery .items {
  display: grid;
  grid-template-columns: repeat(var(--gallery-columns), 1fr);

  max-width: 100vw;

  gap: 0;
}

.react-gallery .item {
  width: var(--gallery-item-width);
  height: var(--gallery-item-height);
  cursor: pointer;
}

.react-gallery .item .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0; /* initial state */
  transition: opacity 500ms ease-in;
}
.react-gallery .item .image.loaded {
  opacity: 1;
}
